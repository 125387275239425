.successContainer {
    height: 100vh;
  }
  .circleIcon {
    font-size: 90px;
    color: #098ffb;
    margin-bottom: 40px;
  }
  .thank-you {
    color: #000000;
    font-family: "Montserrat";
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 27px;
    text-align: center;
    margin-bottom: 14px;
  }
  .thanks-for-taking-th {
    height: 38px;
    width: 302px;
    color: rgba(0,0,0,0.85);
    font-family: "Montserrat";
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
  }
  .rectangle-copy {
    height: 50px;
    width: 162px;
    border-radius: 6px;
    background-color: #C5E6FF;
  }
  .ok {
    height: 19px;
    width: 24px;
    color: #0076CF;
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
    
  }
  .map {
    height: 35px;
    width: 159.97px;
    margin-left: 0px;
    margin-top: 150px;
  }
  .iconimage {
    font-size: 90px;
     height: 100px;
     width: 100px;
     margin-bottom:40px;
  }
  .okbutton {
    height: 50px;
    margin-top:30px;
     width: 162px;
      border-radius: 6px;
      background-color: #C5E6FF;
      border: 1px solid #C5E6FF;
  }