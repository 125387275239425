.rectanglebutton {
    background-color: #0076CF;
    height: 2rem;
    width: 5rem;
    border-radius: 6px;
    border: 1px solid #0076CF;
    margin-right: 0.5rem;
}

.rectangleCancelButton {
    background-color: #D6EDFF;
    height: 2rem;
    width: 5rem;
    border-radius: 6px;
    margin-right: 11px;
    border: 1px solid #D6EDFF;
}

.submit {
    height: 19px;
    width: 15rem;
    color: #FFFFFF;
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
}

.cancel {
    height: 19px;
    width: 56px;
    color: #0076CF;
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
    background-color: #ACDBFF;
}
.pop_up {
    text-align: center;
}
.btn_popup {
    text-align: center;
   
}
.ios-popup {
    margin-bottom: 35px;
}