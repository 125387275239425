  .laundry {
    padding-top: 20px;
    padding-left: 5px;
    padding-right: 5px;
    background-color: #F5F6F7;
    height: 95vh;
    position: relative;
    overflow: auto;
  }
  .col-md-12 {
    padding-right: 0px !important;
  }

  .col-md-3 {
    background: #ff9b00;
    padding: 4%;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .contact-info {
    margin-top: 10%;
  }

  .contact-info img {
    margin-bottom: 15%;
  }

  .contact-info h2 {
    margin-bottom: 10%;
  }

  .col-md-9 {
    background: #fff;
    padding: 3%;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .contact-form label {
    font-weight: 600;
  }

  .select2-container .select2-selection--single {
    height: 34px !important;
  }

  .select2-container--default .select2-selection--single {
    border: 1px solid #ccc !important;
    border-radius: 0px !important;
  }

  .buttonscontainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .elaborate {
    margin-left: 14px;
  }
  .select2 {
    box-shadow: none;
  }

  .column {
    float: left;
    width: 50%;
  }

  .row::after {
    content: "";
    clear: both;
    display: table;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0;
    padding-left: 0;
  }

  .btn-delete {
    position: absolute;
    cursor: pointer;
    right: 2px;
    top: 2px;
    height: 20px;
    width: 20px;
  }

  .tell-us-whats-wrong {
    height: 22px;
    width: 235px;
    color: #5F5F6C;
    font-family: "Montserrat";
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    text-align: justify;
  }

  .customer-name {
    color: rgba(0, 0, 0, 0.85);
    font-family: "Montserrat";
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
  }
  .email-address {
    color: rgba(0, 0, 0, 0.85);
    font-family: "Montserrat";
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
  }

  .shape {
    border: 2px solid #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 14px;
    padding: 0px 1.5px;
    color: #fff;
    position: absolute;
    left: 124px;
    top: 5px;
    background: #3c76cf;
  }

  .location {
    height: 19px;
    width: 200px;
    color: rgba(0, 0, 0, 0.85);
    font-family: "Montserrat";
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    text-align: justify;
  }

  .contact-number {
    color: rgba(0, 0, 0, 0.85);
    font-family: "Montserrat";
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    text-align: justify;
  }

  .equipment {
    height: 19px;
    width: 87px;
    color: rgba(0, 0, 0, 0.85);
    font-family: "Montserrat";
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    text-align: justify;
  }

  .problem {
    height: 19px;
    width: 66px;
    color: rgba(0, 0, 0, 0.85);
    font-family: "Montserrat";
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    text-align: justify;
  }

  .please-elaborate-the {
    height: 19px;
    width: 226px;
    color: rgba(0, 0, 0, 0.85);
    font-family: "Montserrat";
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    text-align: justify;
  }

  .rectangle-copy {
    box-sizing: border-box;
    width: 335px;
    border: 2px solid #0091FF;
    border-radius: 25px;
    visibility: hidden;
    margin-top: 0px;
  }

  .rectangle-copy-10 {
    box-sizing: border-box;
    height: 50px;
    width: 335px;
    border: 1px solid #979797;
    border-radius: 6px;
    background-color: #FFFFFF;
  }

  

  .cancel {
    height: 19px;
    width: 56px;
    color: #0076CF;
    background-color: #D6EDFF;
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
  }

  .submit {
    height: 19px;
    width: 60px;
    color: #FFFFFF;
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
  }

  .cancel-icon {
    border: 2px solid #ACDBFF;
    border-radius: 20%;
    width: 20px;
    height: 20px;
    font-size: 18px;
    padding: 0px 1.5px;
    color: #0076CF;
    position: absolute;
    top: 0.2rem;
    right: 0.2rem;
    background: #ACDBFF;
  }

  .file-img {
    width: 100%;
    height: 100%;
    position: relative;
    padding-right: 0px;
    padding-bottom: 9px;
    border-radius: 6px;
  }

  .lorem-ipsum-is-simpl {
    height: 38px;
    width: 315px;
    color: #29293B;
    font-family: "Montserrat";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 19px;
  }

  .invalid {
    color: #f29191;
    font-family: "Montserrat";
    font-size: 12px;
  }

  .err-message {
    color: #f29191;
    font-family: "Montserrat";
    font-size: 12px;
  }

  .select {
    color: rgba(0, 0, 0, 0.85);
    font-family: "Montserrat";
    font-size: 15px;
  }

  .locaddress {
    color: rgba(0, 0, 0, 0.85);
    font-family: "Montserrat";
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    text-align: justify;
  }

  .rectanglesearch {
    background-color: #D6EDFF;
    height: 50px;
    width: 162px;
    border-radius: 6px;
    margin-right: 11px;
    border: 1px solid #D6EDFF;
  }

  .rectanglesub {
    background-color: #0076CF;
    height: 50px;
    width: 162px;
    border-radius: 6px;
    border: 1px solid #0076CF;
  }

  .imageload {
    width: 200px;
    height: 200px;
  }

  .small,
  small {
    font-size: 65% !important;
    font-weight: 300 !important;
  }

  .swal2-styled.swal2-confirm {
    background-color: #0076CF !important;
    border: 1px solid #0076CF !important;
    border-radius: 6px !important;
    color: #ffffff !important;
  }

  .swal2-styled.swal2-deny {
    background-color: #D6EDFF !important;
    border: 1px solid #D6EDFF !important;
    border-radius: 6px !important;
    color: #0091FF !important;
  }
  .loc-num { 
    height: 50px !important;
    border-color:#979797 !important;
    border-radius: 6px !important;
  }
  .image-upload {
    border-radius: 50px !important;
    margin-left: 0px !important;
    padding: 8px 0px !important;
    text-align: center !important;
    vertical-align: auto !important;
    font-family: "Montserrat" !important;
     box-sizing: border-box !important;
      font-size: 16px !important;
     height: 50px !important;
     padding-top: 12px !important;
     width: 100% !important;
  }
  .customer-field {  
    font-family: "Montserrat" !important; 
    color: #29293B !important;
   box-shadow: none !important;
   height: 50px !important;
   border-radius: 6px !important;
  }
  .email-field {
    font-family: "Montserrat" !important; 
    color: #29293B !important;
   box-shadow: none !important;
   height: 50px !important;
   border-radius: 6px !important;
  }
  .phone-number {
    min-width: 100% !important;
    width:100% !important;
    height: 50px !important;
    box-shadow: none !important;
    color: #29293B !important;
    font-family: "Montserrat" !important;
    border-radius: 6px !important;
  }
  .equipment-name {
    box-shadow: none !important;
    font-family: "Montserrat" !important;
    color: #29293B !important;
    height: 50px !important;
    border-radius: 6px !important;
  }
  .problem-name {
    box-shadow: none !important;
    font-family: "Montserrat" !important;
    color: #29293B !important;
    height: 50px !important;
    border-radius: 6px !important;
  }
  .elaborate-text {
    font-family: "Montserrat" !important;
    color: #29293B !important;
    box-shadow: none !important; 
    max-height: 100px !important;
    border-color: #979797 !important;
    min-height: 50px !important;
    resize: none !important;
    padding: 9px !important;
    padding-top: 12px !important;
    box-sizing: border-box !important;
    font-size: 15px !important;
    border-radius: 6px !important;
  }
  