.arrowicon {
  font-size: 45px !important;
  margin-left: 12px !important;
  color: #0091FF !important;
}

.errcontainer {
  display: flex;
  flex-direction: column;
  width: 90%;
}

.error {
  color: #f29191;
  text-align: left;
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
}

.home {
  padding: 20px;
  height: 100vh;
  background-color: #F5F6F7;

}

.col-md-12 {
  padding-right: 0px !important;
}

.please-enter-the-loc {
  color: rgba(0, 0, 0, 0.85);
  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
}

.welcome-to-commercia {
  color: #5F5F6C;
  font-family: "Montserrat";
  letter-spacing: 0px;
  font-size: 18px;
  font-weight: bold;
}

.rectangle {
  box-sizing: border-box;
  height: 50px;
  border: 1px solid #979797;
  border-radius: 6px;
  background: #FFFFFF;
  outline: none;
  font-size: 16px;
  padding: 0px 12px;
}

.image {
  width: 200px;
  height: 200px;
}
.contact-your-localmanager {
  display: block;
  color: rgba(0, 0, 0, 0.85);
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  padding-top: 10px;
}
.call-911 {
  display: block;
  color: #5F5F6C;
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  padding-top: 10px;
}