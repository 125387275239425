 .help-305-699-3956 {
   height: 10px;
   width: 150px;
   color: #160E6A;
   font-family: "Montserrat";
   font-size: 10px;
   font-weight: 500;
   letter-spacing: 0;
   line-height: 10px;
   text-align: justify;
   margin-left: 54px;
   text-decoration: none;
 }

 .bitmap {
   margin-top: 2.5rem;
   margin-left: 10.5px;
   width: 100%;
 }

 .eng {
   height: 14px;
   width: 25px;
   color: #FFFFFF;
   font-family: "Montserrat";
   font-size: 11px;
   font-weight: 500;
   letter-spacing: 0;
   line-height: 14px;
 }

 .esp {
   height: 14px;
   width: 23px;
   color: #FFFFFF;
   font-family: "Montserrat";
   font-size: 11px;
   font-weight: 500;
   letter-spacing: 0;
   line-height: 14px;
   text-align: right;
 }

 .header {
   padding: 10px;
   border-bottom: 1px solid #01A2E0;
   height: 81px;
   background-color: #FFFFFF;
   
 }

 .img-style {
   width: 78px !important;
   height: 40px !important;
   margin-right: 0px !important;
   margin-top: 12px !important;
   background-color: #FFFFFF !important;
 }

 .lang-name {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100%;
   font-size: 11px;
   color: black;
   padding-right: 2px;
   padding-left: 2px;
 }

 .logo-img {
   padding: 10px;
   border-bottom: 1px solid #01A2E0;
   height: 81px;
   background-color: #FFFFFF;
 }

 .header-image {
   width: 160px;
   height: 30px;
   background-color: #FFFFFF;
 }