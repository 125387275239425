.confirm {
  padding-top: 20px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: #F5F6F7;
  position: relative;
  height: 90vh;
  overflow: auto;

}

.col-md-12 {
  padding-right: 0px !important;
}

.contact-form label {
  font-weight: 600;
}

.enter-locnumber {
  width: 500px;


  display: flex;
  flex-wrap: nowrap;
}

.buttons {
  position: absolute;
  bottom: 50px;
  width: 97.5%;
  z-index: 9999;
}

.buttonscontainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;

}

.please-confirm-the-l {
  color: #5F5F6C;
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}

.address-line-city {
  display: block;
  color: rgba(0, 0, 0, 0.85);
  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  margin-left: 0%;

}

.search-again {
  height: 19px;
  width: 106px;
  color: #0076CF;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
}

.next {
  height: 19px;
  width: 39px;
  color: #FFFFFF;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
}

.errmessage {
  margin-left: 0px;
  margin-top: 16px;
  letter-spacing: 0px;
  font-weight: 500;
  font-size: 18px;
  font-family: "Montserrat";
  color: red;
  text-align: justify;
}

.rectanglesearch {
  background-color: #D6EDFF;
  height: 50px;
  width: 162px;
  border-radius: 6px;
  margin-right: 11px;
  border: 1px solid #D6EDFF;
}

.rectanglesub {
  background-color: #0076CF;
  height: 50px;
  width: 162px;
  border-radius: 6px;
  border: 1px solid #0076CF;
}